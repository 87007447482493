<template>
    <div @click.stop="init()" id="map">
        <vs-button v-if="!isSearching" @click.stop="init(), isSearching = true" size="small" color="dark" class="mb-2">Seleccionar</vs-button>
        <vs-button v-else size="small" color="dark" class="mb-2" @click.stop="clearSelection()">Limpiar selección</vs-button>
        <gmap-map 
            :center="center" 
            :zoom="5.5"
            :options="options"
            ref="map"
            style="width: 100%; height: 500px">
                <gmap-marker 
                    :key="index" v-for="(m, index) in markers" 
                    :position="m.position" 
                    :title="m.title"
                    :clickable="true" 
                    :draggable="false" 
                    @click="centerChanged(m)">
                </gmap-marker>
                <gmap-info-window
                    :options="{
                        maxWidth: 300,
                        pixelOffset: { width: 0, height: -35 }
                    }"
                    :position="infoWindow.position"
                    :opened="infoWindow.open"
                    @closeclick="infoWindow.open=false">
                <div v-html="infoWindow.template"></div>
                </gmap-info-window>

                <gmap-marker 
                    :key="`SELECTED-${index}`" v-for="(m, index) in newMarkers" 
                    :position="m.position" 
                    :title="m.title"
                    :clickable="true" 
                    :draggable="false" 
                    @click="centerChanged(m)">
                </gmap-marker>
        </gmap-map>
    </div>
</template>
<script>
import {gmapApi} from 'vue2-google-maps';
export default {
    data() {
        return {
            center: { lat: this.latitude, lng: this.longitude },
            options: {
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                disableDefaultUi: true
            },
            infoWindow: {
                position: {lat: 0, lng: 0},
                open: false,
                template: ''
            }
            , map: null
            , polygon: null
            , newMarkers: []
            , polygonMarkers: []
            , polygonLocations: []
            , isSearching: false
        }
    },
    async mounted() {
        await this.init()
    },
    computed: {
        google: gmapApi
    },
    props: {
        latitude: Number,
        longitude: Number,
        title: String,
        markers: Array
    },
    methods: {
        async init() {
            // Request needed libraries.
            console.log('init')
            this.map = this.$refs.map;

            google.maps.event.addListenerOnce(this.map.$mapObject, 'click', (e) => {
                // set a marker there, with a small measle icon
                // console.log(e.latLng.lat(), e.latLng.lng());
                if(this.isSearching) {
                    let position = e.latLng;
                    this.polygonLocations.push(position);
                    // now let's add a polygon
                    this.drawPolygon(this.polygonLocations);
                }
            })
        },
        drawPolygon(points) {
            // if(points.length < 3) {
            //     return;
            // }
            //first delete the previous polygon
            if(this.polygon) {
                this.polygon.setMap(null);
            }
            // @see https://developers.google.com/maps/documentation/javascript/examples/polygon-simple
            this.polygon = new google.maps.Polygon({
                paths: points,
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF0000',
                fillOpacity: 0.35,
                map: this.map.$mapObject
            });
            // display to input
            this.displaySelectedMarkers(this.polygon);
        },
        displaySelectedMarkers(polygon) {
            this.newMarkers = [];
            this.markers.forEach((marker) => {
                if(google.maps.geometry.poly.containsLocation(marker.position, polygon)) {
                    this.newMarkers.push(marker);
                }
            });

            this.$emit('selectedMarkers', this.newMarkers);
        },
        clearSelection() {
            if(this.polygon) {
                this.polygon.setMap(null);
            }
            this.newMarkers = [];         
            this.polygonLocations = [];
            this.$emit('selectedMarkers', this.newMarkers);
        },
        centerChanged (marker) {
            this.center = marker.position;
            this.$refs.map.resize(18);
            
            this.infoWindow.position = marker.position;
            this.infoWindow.template = `
                <div style="padding: 8px">
                    <h4 style="margin-top: 0">${marker.title}</h4>
                    <p>Dirección: ${marker.address}</p>
                    <a href="https://www.google.es/maps?q=${marker.position.lat},${marker.position.lng}" target="new">Ver en Maps</a>
                </div>
                `;
            this.infoWindow.open = true
            // console.log(google)
        },

    }
}
</script>