<template>
  <div id="app" class="m-4">
    <div class="vx-row">
      <div class="vx-col w-1/2">
        <GoogleMap ref="gMap" @selectedMarkers="getMarkers" :latitude="position.lat" :longitude="position.lng" :title="'Titulo Marcador'" :markers="markers" />

      </div>
      <div class="vx-col w-1/2">
        <div class="flex justify-between items-center">
          <h5 class="mb-4">Prospectos seleccionados ({{ pgmApplicantsSelected.length }}):</h5>
          <vs-button size="small" color="dark" @click.stop="requestAssign()">Asignar</vs-button>
        </div>
        <vs-divider></vs-divider>
        <!-- {{ pgmApplicantsSelected }} -->
          <div class="leads-list">
            <ul class="columns">
              <li v-for="(lead, index) in pgmApplicantsSelected"> {{ lead.title }}</li>
            </ul>
          </div>
          <div v-if="!hasSelected" class="flex justify-center items-center h-64">
            <span class="text-center">No hay prospectos seleccionados</span>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleMap from "@/views/share/maps/GoogleMap.vue";

export default {
  name: 'App',
  components: {
    GoogleMap
  },
  props: {
    latitude: Number,
    longitude: Number,
    title: String,
    markers: Array
  },
  data() {
    return {
      position: { lat: 19.432608, lng: -99.133209 },
      pgmApplicantsSelected : []
    }
  },
  computed: {
    hasSelected() {
      return this.pgmApplicantsSelected.length > 0;
    }
  },
  methods: {
    getMarkers(e) {
      this.pgmApplicantsSelected = e;
      this.$forceUpdate();
    },
    requestAssign() {
      this.$emit('assingSelected', this.pgmApplicantsSelected);
    }
  },
}
</script>
<style>
  .columns {
    width: 100%;
    column-count: 3;
  }
  .leads-list {
    max-height: 36rem;
    overflow-x: auto;
  }
</style>